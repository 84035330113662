import { TextField } from 'components/common/fields'
import { Field } from 'formik'
import { InputHTMLAttributes, memo } from 'react'

type FormFieldProps = {
  name: string
  title?: string
  materialDesign?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const FormField = ({ name, ...props }: FormFieldProps) => (
  <Field name={name}>
    {({ field, meta }) => (
      <TextField hasError={meta.touched && meta.error} {...props} {...field} />
    )}
  </Field>
)

export default memo(FormField)
