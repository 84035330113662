import { Field } from 'formik'
import { InputHTMLAttributes, memo } from 'react'
import Autocomplete from 'react-google-autocomplete'

import { GOOGLE_PLACE_API_KEY } from '@/configs/env'
import { cnMerge } from '@/utils/cnMerge'

import styles from './TextField.module.css'

type FormFieldAutocompleteProps = {
  name: string
  label?: string
  title?: string
  onSelectSuggestion: (place: google.maps.places.PlaceResult) => void
} & InputHTMLAttributes<HTMLInputElement>

export const FormFieldAutocomplete = memo(
  ({ name, label, ...props }: FormFieldAutocompleteProps) => (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <AutocompleteField
            hasError={meta.touched && meta.error}
            label={label}
            {...field}
            {...props}
          />
        )
      }}
    </Field>
  )
)

type AutocompleteProps = {
  hasError: boolean
  label: string
  onSelectSuggestion: (place: google.maps.places.PlaceResult) => void
} & React.ComponentProps<'input'>

const AutocompleteField = ({
  hasError,
  className,
  onSelectSuggestion,
  label,
  ...props
}: AutocompleteProps) => {
  return (
    <div className={styles.box}>
      <Autocomplete
        apiKey={GOOGLE_PLACE_API_KEY}
        options={{
          types: ['address'],
          componentRestrictions: { country: 'us' }
        }}
        onPlaceSelected={(place) => onSelectSuggestion(place)}
        id={props.id ?? props.name}
        aria-invalid={!!hasError}
        className={cnMerge([
          styles.input,
          hasError ? styles.invalid : styles.valid,
          className
        ])}
        {...props}
      />
      <label htmlFor={props.id ?? props.name} className={styles.label}>
        {label}
      </label>
    </div>
  )
}

FormFieldAutocomplete.displayName = 'FormFieldAutocomplete'
