import { ComponentProps, memo } from 'react'

import { cnMerge } from '@/utils/cnMerge'

import styles from './TextField.module.css'

type TextareaFieldProps = {
  hasError: boolean
} & ComponentProps<'textarea'>

export const TextareaField = memo(
  ({ hasError, placeholder, className, ...props }: TextareaFieldProps) => {
    return (
      <div className={styles.box}>
        <textarea
          id={props.id ?? props.name}
          placeholder={placeholder}
          className={cnMerge([
            styles.input,
            hasError ? styles.invalid : styles.valid,
            className
          ])}
          {...props}
        />
        <label htmlFor={props.id ?? props.name} className={styles.label}>
          {placeholder}
        </label>
      </div>
    )
  }
)

TextareaField.displayName = 'TextareaField'
