import { memo } from 'react'

type DropdownArrowIconProps = {
  width?: number
  height?: number
  colorHex?: string
  className?: string
}

const DropdownArrowIcon = ({
  width = 12,
  height = 8,
  colorHex = '#1f2837',
  className
}: DropdownArrowIconProps) => (
  <svg
    aria-hidden="true"
    width={width}
    height={height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11 1.5L6 6.5L1 1.5" stroke={colorHex} strokeWidth="2" />
  </svg>
)

export default memo(DropdownArrowIcon)
