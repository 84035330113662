import { HTMLAttributes, ReactNode } from 'react'

import { cnMerge } from '@/utils/cnMerge'

type IPageTitleProps = {
  children: ReactNode
} & HTMLAttributes<HTMLHeadingElement>

const PageTitle = ({
  children,
  className = 'mb-3',
  ...restProps
}: IPageTitleProps) => (
  <h1
    className={cnMerge('font-serif text-4xl font-medium', className)}
    {...restProps}
  >
    {children}
  </h1>
)

export default PageTitle
