import Image from 'next/image'
import Link from 'next/link'
import { memo } from 'react'

import { EXTERNAL_LINKS } from '@/configs/externalLinks'

const bgStyle = { backgroundImage: 'url(/images/onboarding-footer-back.svg)' }

const year = new Date().getFullYear()

const SiteFooter = () => (
  <footer className="bg-cover pt-[6rem] pb-[4rem]" style={bgStyle}>
    <div className="px-3 mx-auto max-w-[83.125rem]">
      <div className="flex mb-7 space-x-[3.2rem]">
        <Image
          width="117"
          height="117"
          src="/images/suvie-seal-white.png"
          alt="Suvie"
        />
        <div className="pt-6 font-serif leading-relaxed text-white">
          <p>Need Help?</p>
          <p>
            Email us at <a href="mailto:help@suvie.com">help@suvie.com</a>
          </p>
        </div>
      </div>
      <p className="mb-6 text-gray-3 text-[0.625rem]">
        Meals for purchase may vary based on availability. Time savings are
        estimates and not a guarantee. Some images simulated and some servings
        suggested with finishing steps and consumer supplied additional
        ingredients. Meal coupon offers do not apply to the Suvie appliance. For
        all coupons unless otherwise specified only one coupon is valid per
        customer for one-time use only. Coupons are valid only during their
        coupon period. Sales tax, shipping & handling do not qualify for any
        coupons. Coupons are not redeemable for cash or gift cards. Coupons are
        not valid for prior purchases. Coupons may not be combined with any
        other coupons, discounts, offers, or promotions. Void if altered,
        copied, transferred, auctioned or sold. Offer valid on in-stock items
        only. No rain checks issued. Other restrictions may apply. *Additional
        shipping and handling charges may apply see terms and conditions for
        details. Features subject to change on final product. Certified balanced
        meals have been approved by a registered dietitian and / or physician to
        meet general government health guidelines for a balanced diet. Before
        starting any diet program please consult your physician before beginning
        any diet program. &quot;Patents Pending. Copyright 2017 - {year}, Suvie.
      </p>
      <Link
        href={EXTERNAL_LINKS.ACCESSIBILITY}
        className="text-white text-[0.75rem]"
      >
        Accessibility Statement
      </Link>
    </div>
  </footer>
)

export default memo(SiteFooter)
