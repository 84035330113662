import { memo } from 'react'

type CloseIconProps = {
  width?: number
  height?: number
  colorHex?: string
  className?: string
}

const CloseIcon = ({
  width = 16,
  height = 16,
  colorHex = '#1f2837',
  className
}: CloseIconProps) => (
  <svg
    aria-hidden="true"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.4 1.6001L1.59998 14.4001M14.4 14.4001L1.59998 1.6001"
      stroke={colorHex}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(CloseIcon)
