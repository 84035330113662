import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import Button from '@/components/Button'
import OnboardingLayout from '@/components/OnboardingLayout'
import { PATHS } from '@/configs/paths'

type AuthLayoutProps = {
  children: ReactNode
  withoutExternalLinks?: boolean
  withoutMagicSigninLink?: boolean
}

const AuthLayout = ({
  children,
  withoutExternalLinks,
  withoutMagicSigninLink
}: AuthLayoutProps) => {
  const router = useRouter()

  return (
    <OnboardingLayout
      step="auth"
      mainClassName="bg-no-repeat bg-center bg-cover text-center py-5 md:pt-10 md:pb-[16.875rem] bg-[url(/images/auth.jpg)]"
    >
      <div className="py-9 px-3 md:px-12 mx-auto bg-white rounded max-w-[33.125rem]">
        {children}

        {!withoutExternalLinks && (
          <>
            <Link
              href={
                router?.pathname === PATHS.AUTH.SIGNIN
                  ? PATHS.AUTH.RESET_PASSWORD
                  : PATHS.AUTH.SIGNIN
              }
              className="font-sans text-primary"
            >
              {router?.pathname === PATHS.AUTH.SIGNIN
                ? 'Reset My Password'
                : 'Sign In'}
            </Link>
            {!withoutMagicSigninLink && (
              <>
                <p>or</p>
                <Link
                  href={PATHS.AUTH.MAGIC_SIGNIN}
                  className="font-sans text-primary"
                >
                  New? Set up Your Suvie Account
                </Link>
              </>
            )}
          </>
        )}
      </div>

      <div className="flex overflow-hidden flex-col-reverse sm:flex-row mx-auto mt-4 bg-white rounded max-w-[33.125rem]">
        <div className="py-5 px-6 sm:w-1/2 shrink-0">
          <h2 className="font-serif text-2xl font-normal text-secondary">
            Don’t have a Suvie Kitchen Robot?
          </h2>
          <p className="mt-2 text-gray-3">
            Save time and eat better by ordering your magical kitchen robot
            today
          </p>
          <div className="mt-3">
            <Button asChild className="h-8 rounded-lg min-h-fit">
              <a href="https://www.suvie.com/shop/">Shop Now</a>
            </Button>
          </div>
        </div>
        <div className="relative sm:w-1/2 sm:h-auto h-[200px] shrink-0">
          <Image
            src="/images/checkout-start.jpg"
            fill
            className="object-cover sm:object-left sm:scale-125"
            sizes="(min-width: 640px) 265px, 100vw"
            alt=""
          />
        </div>
      </div>
    </OnboardingLayout>
  )
}

export default AuthLayout
