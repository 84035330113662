import { memo } from 'react'

type CheckIconProps = {
  width?: number
  height?: number
  colorHex?: string
  className?: string
}

const CheckIcon = ({
  width = 12,
  height = 9,
  colorHex = '#1f2837',
  className
}: CheckIconProps) => (
  <svg
    aria-hidden="true"
    width={width}
    height={height}
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.91064 4.49851L4.63792 7.22579L10.0925 1.77124"
      stroke={colorHex}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default memo(CheckIcon)
