import { ComponentProps, memo } from 'react'
import { cnMerge } from 'utils/cnMerge'

import DropdownArrowIcon from '@/components/DropdownArrowIcon'

import styles from './TextField.module.css'

type SelectFieldProps = {
  label: string
  hasError: boolean
} & ComponentProps<'select'>

export const SelectField = memo(
  ({ label, hasError, children, className, ...props }: SelectFieldProps) => {
    return (
      <div className={styles.box}>
        <DropdownArrowIcon
          colorHex={props.disabled ? '#979797' : '#1f2837'}
          className="absolute top-1/2 right-3 z-30 -translate-y-1/2 w-[12px] h-[12px]"
        />
        <select
          id={props.id ?? props.name}
          aria-invalid={!!hasError}
          placeholder={label}
          className={cnMerge([
            styles.input,
            hasError ? styles.invalid : styles.valid,
            className
          ])}
          {...props}
        >
          {children}
        </select>
        <label className={styles.label}>{label}</label>
      </div>
    )
  }
)

SelectField.displayName = 'SelectField'
