import { ComponentProps, memo } from 'react'
import MaskedInput from 'react-text-mask'
import { cnMerge } from 'utils/cnMerge'

import styles from './TextField.module.css'

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

type TextFieldProps = {
  hasError: boolean
} & ComponentProps<'input'>

export const PhoneField = memo(
  ({ hasError, placeholder, className, ...props }: TextFieldProps) => (
    <div className={styles.box}>
      <MaskedInput
        id={props.id ?? props.name}
        type="tel"
        mask={phoneNumberMask}
        placeholder={placeholder}
        aria-invalid={!!hasError}
        className={cnMerge([
          styles.input,
          hasError ? styles.invalid : styles.valid,
          className
        ])}
        {...props}
      />
      <label htmlFor={props.id ?? props.name} className={styles.label}>
        {placeholder}
      </label>
    </div>
  )
)

PhoneField.displayName = 'PhoneField'
