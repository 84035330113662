import { ReactNode, ReactText, useMemo } from 'react'

import OnboardingHeader from '@/components/OnboardingHeader'
import SiteFooter from '@/components/SiteFooter'
import { useWindowSize } from '@/hooks'
import { cnMerge } from '@/utils/cnMerge'

type OnboardingLayoutProps = {
  step: string
  children: ReactNode
  mainClassName?: string
  mainStyle?: Record<string, ReactText>
}

const OnboardingLayout = ({
  step,
  children,
  mainClassName,
  mainStyle
}: OnboardingLayoutProps) => {
  const { isMobile } = useWindowSize()

  const containerStyle = useMemo(
    () => ({
      backgroundImage:
        ['welcome'].includes(step) && isMobile
          ? 'linear-gradient(90deg, #c8c8c8, #c2c2c2, #c6c6c6, #cfcfcf, #dadada)'
          : 'none'
    }),
    [step, isMobile]
  )

  return (
    <div className="flex flex-col min-h-screen" style={containerStyle}>
      <OnboardingHeader step={step} />
      <main className={cnMerge('p-3 grow', mainClassName)} style={mainStyle}>
        {children}
      </main>
      <div className="hidden md:block -mt-12">
        <SiteFooter />
      </div>
    </div>
  )
}

export default OnboardingLayout
