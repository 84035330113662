import { ReactNode, ReactText } from 'react'
import { toast, ToastPosition, TypeOptions } from 'react-toastify'

import CheckIcon from '@/components/CheckIcon'
import CloseIcon from '@/components/CloseIcon'
import InfoIcon from '@/components/InfoIcon'

export const showNotification = (
  message: ReactNode | string,
  type: TypeOptions = toast.TYPE.INFO,
  position: ToastPosition = toast.POSITION.TOP_RIGHT
): ReactText => toast(message, { type, position })

export const showSuccess = (message?: ReactNode | string): ReactText =>
  showNotification(
    <>
      <div
        data-cy="notification-success"
        className="flex justify-center items-center mr-2 w-5 h-5 bg-primary rounded-full shrink-0"
      >
        <CheckIcon width={12} height={12} colorHex="#fff" />
      </div>
      {message}
    </>,
    'success'
  )

export const showError = (message?: ReactNode | string): ReactText =>
  showNotification(
    <>
      <div
        data-cy="notification-error"
        className="flex justify-center items-center mr-2 w-5 h-5 bg-danger rounded-full shrink-0"
      >
        <CloseIcon width={12} height={12} colorHex="#fff" />
      </div>
      {message}
    </>,
    'error'
  )

export const showWarning = (message?: ReactNode | string): ReactText =>
  showNotification(
    <>
      <div
        data-cy="notification-warning"
        className="flex justify-center items-center mr-2 w-5 h-5 rounded-full shrink-0 bg-[#d7a25e]"
      >
        <InfoIcon width={12} height={12} colorHex="#fff" />
      </div>
      {message}
    </>,
    'warning'
  )
