import { ComponentProps, memo } from 'react'

import { cnMerge } from '@/utils/cnMerge'

import styles from './TextField.module.css'

type TextFieldProps = {
  hasError: boolean
  materialDesign?: boolean
} & ComponentProps<'input'>

export const TextField = memo(
  ({
    hasError,
    placeholder,
    className,
    materialDesign = true,
    ...props
  }: TextFieldProps) => {
    if (!materialDesign) {
      return (
        <input
          id={props.id ?? props.name}
          placeholder={placeholder}
          aria-invalid={!!hasError}
          className={cnMerge([styles.input, className])}
          {...props}
        />
      )
    }

    return (
      <div className={styles.box}>
        <input
          id={props.id ?? props.name}
          placeholder={placeholder}
          aria-invalid={!!hasError}
          className={cnMerge([
            styles.input,
            hasError ? styles.invalid : styles.valid,
            className
          ])}
          {...props}
        />
        <label htmlFor={props.id ?? props.name} className={styles.label}>
          {placeholder}
        </label>
      </div>
    )
  }
)

TextField.displayName = 'TextField'
