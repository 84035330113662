import Image from 'next/image'
import { memo } from 'react'

import { META_DEFAULTS } from '@/configs/misc'

type SiteLogoProps = {
  className?: string
}

const SiteLogo = ({ className = '' }: SiteLogoProps) => (
  <div className={className}>
    <Image
      width={86}
      height={24}
      src="/images/logo.svg"
      priority={true}
      alt={META_DEFAULTS.title}
    />
  </div>
)

export default memo(SiteLogo)
