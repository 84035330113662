import dynamic from 'next/dynamic'
import { memo } from 'react'

import { cnMerge } from '@/utils/cnMerge'

// I have some problem with product build and next.js 12.1.6, so I had to use dynamic imports to solve the problem
// https://github.com/jaredpalmer/formik/issues/1464#issuecomment-1141330412
const ErrorMessage = dynamic(
  () => import('formik').then((module) => module.ErrorMessage),
  { ssr: false }
)

type FormFieldErrorProps = {
  name: string
  className?: string
}

const FormFieldError = ({ name, className }: FormFieldErrorProps) => (
  <ErrorMessage
    name={name}
    component="div"
    className={cnMerge('form-error', className)}
  />
)

export default memo(FormFieldError)
