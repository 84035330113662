import { memo } from 'react'

type BackIconProps = {
  width?: number
  height?: number
  colorHex?: string
  className?: string
}

const BackIcon = ({
  width = 15,
  height = 14,
  colorHex = '#1f2837',
  className
}: BackIconProps) => (
  <svg
    aria-hidden="true"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="m7.5 1-6 6m0 0 6 6m-6-6H15" stroke={colorHex} strokeWidth="2" />
  </svg>
)

export default memo(BackIcon)
