import { memo } from 'react'

type InfoIconProps = {
  width?: number
  height?: number
  colorHex?: string
  className?: string
}

const InfoIcon = ({
  width = 14,
  height = 14,
  colorHex = '#1f2837',
  className
}: InfoIconProps) => (
  <svg
    aria-hidden="true"
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
      stroke={colorHex}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 10V7"
      stroke={colorHex}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 4H7.006"
      stroke={colorHex}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(InfoIcon)
