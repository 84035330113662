import { Slot, Slottable } from '@radix-ui/react-slot'
import { ComponentPropsWithoutRef, memo, useMemo } from 'react'

import { TButtonTheme } from '@/types'
import { getButtonClassnames } from '@/utils/misc'

type ButtonProps = {
  theme?: TButtonTheme
  isLoading?: boolean
  loaderColorHex?: string
  asChild?: boolean
} & ComponentPropsWithoutRef<'button'>

/**
 * @summary It's a polymorphic button. Can be used as an external link, internal Link or normal Button.
 *
 * @example
 * ```tsx
 * // As an External Link
 * <Button asChild className="h-8 rounded-lg min-h-fit">
 *   <a href="https://www.suvie.com/shop/">Shop Now</a>
 * </Button>
 *```
 * @example
 * ```tsx
 * // As an Internal Link (does not reload). Wrap it with NextLink and use asChild prop
 * <NextLink href="/" passHref>
 *   <Button asChild className="h-8 rounded-lg min-h-fit">
 *     <a>Shop Now</a>
 *   </Button>
 * </NextLink>
 * ```
 * @example
 * ```tsx
 * // As a normal Button
 * <Button onClick={() => alert('Clicked')} className="h-8 rounded-lg min-h-fit">
 *   <a>Shop Now</a>
 * </Button>
 * ```
 */
const Button = ({
  children,
  theme = 'primary',
  className,
  isLoading,
  loaderColorHex,
  asChild,
  ...props
}: ButtonProps) => {
  const Component = asChild ? Slot : 'button'

  const buttonClassnames = useMemo(
    () => getButtonClassnames(theme, className),
    [theme, className]
  )

  return (
    <Component className={buttonClassnames} {...props}>
      {isLoading && (
        <svg
          aria-hidden="true"
          width="16"
          height="16"
          className="mr-3 -ml-1 animate-spin motion-reduce:animate-none"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke={loaderColorHex ?? 'currentColor'}
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill={loaderColorHex ?? 'currentColor'}
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      <Slottable>{children}</Slottable>
    </Component>
  )
}

export default memo(Button)
