import { useRouter } from 'next/router'
import { memo } from 'react'

import BackIcon from '@/components/BackIcon'
import SiteLogo from '@/components/SiteLogo'
import { cnMerge } from '@/utils/cnMerge'

type OnboardingHeaderProps = {
  step: string
}

const stepTitleMap = {
  servings: 'Your Meal Servings Preferences',
  subscription: 'Your Meal Subscription Preferences',
  paymentInformation: 'Your Subscription Payment Information'
}

const OnboardingHeader = ({ step }: OnboardingHeaderProps) => {
  const router = useRouter()

  const isLogoCenteredLayout = ['auth', 'welcome'].includes(step)

  return (
    <div
      className={cnMerge('relative text-center bg-white', {
        'md:shadow-sm': ['auth', 'welcome'].includes(step)
      })}
    >
      {isLogoCenteredLayout ? (
        <a href="https://www.suvie.com/" target="_blank" rel="noreferrer">
          <SiteLogo className="p-3 md:p-4" />
        </a>
      ) : (
        <>
          <div className="flex justify-center items-center p-3 md:p-4">
            <button
              onClick={() => router.back()}
              title="Go to previous step"
              aria-label="Go back"
              className="md:hidden absolute left-3"
            >
              <BackIcon />
            </button>

            <a
              href="https://www.suvie.com/"
              target="_blank"
              rel="noreferrer"
              className="hidden md:block absolute left-3"
            >
              <SiteLogo />
            </a>

            <div className="md:text-lg font-bold text-secondary">
              {stepTitleMap[step]}
            </div>
          </div>

          <div className="w-full h-2 bg-gray-2">
            <div
              className={cnMerge(
                'h-full bg-primary',
                { 'w-2/4': step === 'servings' },
                { 'w-3/4': step === 'subscription' },
                { 'w-4/4': step === 'paymentInformation' }
              )}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(OnboardingHeader)
